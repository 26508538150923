import React from "react";
import PropTypes from "prop-types";

import PhoneInput from "react-phone-input-2";

import {
  Input,
  Form,
  DatePicker,
  Select,
  Switch,
  Button as AntdButton,
  TimePicker,
  InputNumber,
  Checkbox,
  Radio,
  Slider,
  Avatar,
  Space,
  Spin,
} from "antd";
// import "./RoundedFormItems.scss";
import "./FlatFormItem.scss";
import "react-phone-input-2/lib/style.css";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";

import { ReactComponent as SearchIco } from "assets/icons/searchIco.svg";

import { Button } from "../Buttons/Buttons";

import IMask from "imask";
import moment from "moment";
import myxss from "containers/FormsManagement/FormBuilder/form-elements/myxss";

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const DATE_FORMAT = "MM/DD/YYYY";

const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE },
  },
  format: (date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});

export const FormInput = ({
  name,
  required,
  type,
  label,
  placeholder,
  initialValue,
  formItemStyles,
  styles,
  disabled,
  max,
  addonAfter,
  variant = "filled",
  readOnly,
  hidden = false,
  onChange,
  onBlur,
  normalize = (e) => e,
  rules,
  prefix,
  formItemClassName,
  addonBefore
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      normalize={normalize}
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      rules={
        rules ?? [
          {
            required,
            message: "Required",
          },
          {
            type: type,
            message: `Please Enter a valid ${label || "email"}`,
          },
        ]
      }
      style={formItemStyles}
      className={`flatFormItem ${variant === "underlined" ? "underlined" : ""}
      ${variant === "outlined" ? "outlined" : ""}
      ${readOnly ? "readOnly" : ""} 
      ${formItemClassName}
      ${hasLabelValue ? "" : "label-d-none"}
      `}
      hidden={hidden}
    >
      <Input
        onBlur={onBlur}
        prefix={prefix}
        readOnly={readOnly}
        defaultValue={initialValue}
        placeholder={placeholder}
        style={{ padding: 12, ...styles }}
        type={type === "email" ? "text" : type}
        disabled={disabled}
        maxLength={max}
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        onChange={onChange && onChange}
      />
    </FormItem>
  );
};

export const FormInputNumber = ({
  name,
  required,
  type,
  label,
  placeholder,
  initialValue,
  styles,
  disabled,
  min,
  max,
  maxlength,
  variant = "filled",
  readOnly,
  formItemStyles,
  formItemClassName,
  rules,
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      rules={
        rules ?? [
          {
            required,
            message: "Required",
          },
        ]
      }
      style={formItemStyles}
      className={`flatFormItem inputNumber
      ${variant === "underlined" ? "underlined" : "filled"}
      ${readOnly ? "readOnly" : ""} ${formItemClassName}
      ${hasLabelValue ? "" : "label-d-none"}

      `}
    >
      <InputNumber
        placeholder={placeholder}
        style={styles}
        disabled={disabled}
        min={min}
        max={max}
        maxLength={maxlength}
      />
    </FormItem>
  );
};

export const FormPhoneNumber = ({
  name,
  required,
  type,
  label,
  placeholder,
  initialValue,
  formItemStyles,
  styles,
  disabled,
  max,
  addonAfter,
  variant = "filled",
  readOnly,
  hidden = false,
  normalize,
  formItemClassName,
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      disabled={disabled}
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      validateFirst
      rules={[
        {
          required,
          message: "Required",
        },
        {
          message: "Please enter a valid phone number ",
          validateTrigger: ["onBlur", "onChange", "onSubmit"],
          validator: (e, value) => {
            if (required !== true && (value === undefined || value === "1")) {
              return Promise.resolve();
            }
            if (value.length !== 0 && value.length < 10) {
              return Promise.reject();
            }
            return Promise.resolve();
          },
        },
      ]}
      style={formItemStyles}
      className={`flatFormItem phoneNumber ${
        variant === "underlined" ? "underlined" : ""
      } ${formItemClassName}
      ${hasLabelValue ? "" : "label-d-none"}
      `}
      hidden={hidden}
    >
      <PhoneInput
        country={"us"}
        onlyCountries={["us"]}
        placeholder="+1"
        disabled={disabled}
      />
    </FormItem>
  );
};

export const FormDatePicker = ({
  name,
  required,
  label,
  initialValue,
  styles,
  onClick,
  variant,
  formItemStyles,
  disabledDate,
  rules,
  defaultValue,
  validateFirst,
  form,
  onChange,
  disabled,
  formItemClassName,
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      disabled={disabled}
      initialValue={null}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      validateFirst={validateFirst}
      className={`flatFormItem icon-gray ${
        variant === "underlined" ? "underlinedDatePicker" : "filledDatePicker"
      } ${formItemClassName}
      ${hasLabelValue ? "" : "label-d-none"}
      `}
      rules={
        rules ?? [
          {
            required,
            message: "Required",
          },
        ]
      }
      trigger="onSelect" // <<<< THIS ONE
      style={formItemStyles}
      onKeyUp={(e) => {
        if (
          e.target.value === "" ||
          e.target.value === null ||
          e.target.value === undefined
        ) {
          form.setFieldsValue({
            [name]: undefined,
          });
          onChange?.(undefined);
        }
      }}
    >
      <DatePicker
        disabled={disabled}
        format={DATE_FORMAT}
        onKeyDown={(event) => {
          let inputValue = event.target.value;
          let inputValueWithKey = inputValue + event.key;
          const target = event.target;
          const regex = /^\d{2}\/\d{2}\/\d{4}$/g;

          let newValue = MASKED.resolve(inputValue);
          let newValueWithKey = newValue + event.key;

          target.value = newValue;

          if (newValueWithKey.match(regex) && form) {
            const date = moment(newValueWithKey, DATE_FORMAT);
            const dateWithoutTimezone = moment.utc(date.format('YYYY-MM-DD') + 'T00:00:00+00:00', "YYYY-MM-DDTHH:mm:ssZ");

            if (!(disabledDate && disabledDate(date))) {
              form.setFieldsValue({
                [name]: dateWithoutTimezone,
              });
              onChange?.(dateWithoutTimezone);
              event.preventDefault();
            }
          }
        }}
        onChange={(date, dateString) => {
          const dateWithoutTimezone = moment.utc(date.format('YYYY-MM-DD') + 'T00:00:00+00:00', "YYYY-MM-DDTHH:mm:ssZ");
          form.setFieldsValue({
            [name]:  dateWithoutTimezone,
          });
          onChange?.(dateWithoutTimezone);
        }}
        onClick={onClick}
        placeholder={DATE_FORMAT.toLowerCase()}
        disabledDate={disabledDate}
        // onClick={onClick && onClick}
        defaultValue={defaultValue}
        className="home_select"
        style={styles}
      />
    </FormItem>
  );
};

export const FormTimePicker = ({
  name,
  required,
  label,
  initialValue,
  styles,
  onClick,
  variant,
  formItemStyles,
  disabledDate,
  rules,
  defaultValue,
  validateFirst,
  form,
  onChange,
  disabled,
  validateTrigger,
  dependencies,
  allowClear = true,
  hidden = false,
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      hidden={hidden}
      disabled={disabled}
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      validateFirst={validateFirst}
      className={`flatFormItem ${
        variant === "underlined" ? "underlinedDatePicker" : ""
      }
      ${hasLabelValue ? "" : "label-d-none"}
      `}
      validateTrigger={validateTrigger}
      dependencies={dependencies}
      rules={
        rules ?? [
          {
            required,
            message: "Required",
          },
        ]
      }
      style={formItemStyles}
    >
      <TimePicker
        disabled={disabled}
        name={name}
        use12Hours
        format="h:mm A"
        allowClear={allowClear}
      />
    </FormItem>
  );
};

export const FormTimeRangePicker = ({
  name,
  required,
  label,
  initialValue,
  styles,
  onClick,
  variant,
  formItemStyles,
  disabledDate,
  rules,
  defaultValue,
  validateFirst,
  form,
  onChange,
  disabled,
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      disabled={disabled}
      initialValue={null}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      validateFirst={validateFirst}
      className={`flatFormItem ${
        variant === "underlined" ? "underlinedDatePicker" : ""
      }
      ${hasLabelValue ? "" : "label-d-none"}
      `}
      rules={
        rules ?? [
          {
            required,
            message: "Required",
          },
        ]
      }
      style={formItemStyles}
    >
      <TimePicker.RangePicker name={name} use12Hours format="h:mm A" />
    </FormItem>
  );
};

export const FormSelect = ({
  name,
  required,
  label,
  placeholder,
  options,
  initialValue,
  styles,
  onChange,
  disabled,
  dropdownStyle,
  mode,
  onClick,
  variant,
  formItemStyles,
  renderLabel,
  renderValue,
  isSearch = false,
  feedBack = true,
  className,
  rules,
  loading,
  onSearch,
  filterOption,
  showSearch,
  optionLabelProp,
  renderOption,
  isOptionDisabled,
  value,
  allowClear = false,
  tagRender,
  dropdownRender,
  labelPrefix,
  hidden,
  formItemClassName,
  autoClearSearchValue,
  notFoundContent,
  onPopupScroll,
  searchValue
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      hasFeedback={feedBack}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      initialValue={initialValue ?? undefined}
      name={name}
      validateFirst
      rules={
        rules ?? [
          {
            required,
            message: "Required",
          },
        ]
      }
      className={`flatFormItem select ${
        variant === "underlined" ? "underlined" : ""
      } ${isSearch ? "searchField" : "filled"} ${FlatFormItem}
      ${formItemClassName}
      ${hasLabelValue ? "" : "label-d-none"}
      `}
      style={{ ...formItemStyles }}
      hidden={hidden}
    >
      <Select
        searchValue={searchValue}
        autoClearSearchValue={autoClearSearchValue}
        tagRender={tagRender}
        allowClear={allowClear}
        value={value}
        onClick={onClick && onClick}
        mode={mode}
        dropdownStyle={{ ...dropdownStyle }}
        className={` ${className}`}
        placeholder={placeholder}
        style={styles}
        onChange={onChange}
        disabled={disabled}
        loading={loading}
        // showArrow={isSearch ? false : true}
        suffixIcon={
          loading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : isSearch ? (
            <SearchIco />
          ) : undefined
        }
        showSearch={showSearch}
        onSearch={onSearch}
        filterOption={filterOption}
        optionLabelProp={optionLabelProp}
        dropdownRender={dropdownRender}
        defaultValue={initialValue ?? undefined}
        notFoundContent={notFoundContent}
        onPopupScroll={onPopupScroll}
      >
        {options?.map?.((opt) => (
          <Option
            disabled={isOptionDisabled?.(opt)}
            key={renderValue ? opt[renderValue] : opt}
            value={renderValue ? opt[renderValue] : opt}
            label={renderLabel ? opt[renderLabel] : opt}
          >
            {labelPrefix && labelPrefix}

            {renderOption ? (
              renderOption(opt)
            ) : (
              <>{renderLabel ? opt[renderLabel] : opt}</>
            )}
          </Option>
        ))}
      </Select>
    </FormItem>
  );
};

export const FormSwitch = ({
  name,
  required,
  label,
  initialValue,
  formItemStyles = {},
  ...props
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      {...props}
      className={`
    ${hasLabelValue ? "" : "label-d-none"}
    `}
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      valuePropName="checked"
      rules={[
        {
          required,
          message: "Please input task",
        },
      ]}
      style={{
        ...formItemStyles,
      }}
    >
      <Switch />
    </FormItem>
  );
};

// variant can be primary | secondary
export const FormButton = ({
  htmlType,
  text,
  size,
  style,
  onClick,
  variant = "filled",
  disabled,
  loading,
  className,
  rounded = false,
  formItemStyles = {},
  type,
}) => {
  return (
    <FormItem style={formItemStyles}>
      <Button
        type={type}
        loading={loading}
        disabled={disabled}
        size={size}
        onClick={onClick ? onClick : () => {}}
        style={style}
        htmlType={htmlType ? htmlType : "button"}
        variant={variant}
        className={className}
        rounded={rounded}
      >
        {text}
      </Button>
    </FormItem>
  );
};

FormButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["filled", "outlined"]),
  className: PropTypes.string,
  rounded: PropTypes.bool,
  formItemStyles: PropTypes.object,
};

export const FormHiddenSubmitButton = ({ submitRef }) => {
  return (
    <Form.Item
      style={{ display: "none" }}
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <AntdButton ref={submitRef} type="primary" htmlType="submit"></AntdButton>
    </Form.Item>
  );
};

export const FormInputArea = ({
  name,
  required,
  label,
  placeholder,
  message,
  initialValue,
  styles,
  formItemStyles,
  rows,
  variant = "filled",
  disabled,
  formItemClassName,
}) => {
  const hasLabelValue = label && label?.length > 0;
  return (
    <FormItem
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      style={formItemStyles}
      rules={[
        {
          required,
          message: "Required",
        },
      ]}
      className={`flatFormItem ${variant === "underlined" ? "underlined" : ""}
      ${formItemClassName}
      ${hasLabelValue ? "" : "label-d-none"}

      `}
    >
      <TextArea
        disabled={disabled}
        placeholder={placeholder}
        style={styles}
        rows={rows || 4}
      />
    </FormItem>
  );
};

export const FormRadioGroup = ({
  name,
  label,
  style,
  options,
  required,
  className,
  inline = true,
  vertical = false,
  initialValue,
  onChangeHandler,
  formItemClassName,
  hidden,
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      hidden={hidden}
      name={name}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      className={`flatFormItem flatFormItemRadioGroup ${className ?? ""}   ${
        inline ? "inlined" : "vertical"
      } ${formItemClassName}
      ${hasLabelValue ? "" : "label-d-none"}

      `}
      style={style}
      rules={[
        {
          required,
          message: "Required",
        },
      ]}
      initialValue={initialValue}
    >
      {vertical ? (
        <Space direction="vertical">
          <Radio.Group options={options} onChange={onChangeHandler} />
        </Space>
      ) : (
        <Radio.Group options={options} onChange={onChangeHandler} />
      )}
    </FormItem>
  );
};

export const FormCheckboxGroup = ({
  name,
  label,
  formItemStyles,
  style,
  options,
  required,
  className,
  initialValue,
  disabled,
  inline = true,
  formItemClassName,
  ...props
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      {...props}
      initialValue={initialValue}
      name={name}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      className={`flatFormItem flatFormItemCheckboxGroup ${className ?? ""}
      ${inline ? "inlined" : "vertical"}
      ${formItemClassName}
      ${hasLabelValue ? "" : "label-d-none"}

      `}
      style={formItemStyles}
      rules={[
        {
          required,
          message: "Required",
        },
      ]}
    >
      <Checkbox.Group disabled={disabled} options={options} style={style} />
    </FormItem>
  );
};

export const FormCheckbox = ({
  name,
  formItemStyles,
  style,
  children,
  required,
  className,
  initialValue,
  disabled,
  hidden = false,
  checked,
  onChange = () => {},
}) => {
  return (
    <FormItem
      name={name}
      className={`flatFormItemCheckbox ${className ?? ""}`}
      style={formItemStyles}
      initialValue={initialValue}
      hidden={hidden}
      rules={[
        {
          required,
          message: "Required",
        },
      ]}
      valuePropName="checked"
    >
      <Checkbox
        checked={checked}
        disabled={disabled}
        style={style}
        onChange={onChange}
      >
        {children}
      </Checkbox>
    </FormItem>
  );
};

export const FormRadio = ({
  name,
  formItemStyles,
  style,
  children,
  required,
  className,
  options,
}) => {
  return (
    <FormItem
      name={name}
      className={`flatFormItemCheckbox ${className ?? ""}`}
      style={formItemStyles}
      rules={[
        {
          required,
          message: "Required",
        },
      ]}
    >
      <Checkbox style={style}>{children}</Checkbox>
    </FormItem>
  );
};

export const FormPassword = ({
  name,
  required,
  label,
  placeholder,
  formItemStyles,
  styles,
  disabled,
  max,
  addonAfter,
  variant = "filled",
  rules,
  dependencies = [],
  validateTrigger,
  onFocus,
  onBlur,
  className,
  id,
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      validateTrigger={validateTrigger}
      rules={
        rules ?? [
          {
            required,
            message: "Required",
          },
          // {
          //   pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/,
          //   message:
          //     "Enter atleast one uppercase, lowercase and a number; 8 characters ",
          // },
        ]
      }
      dependencies={dependencies}
      style={formItemStyles}
      className={`flatFormItem ${className} ${
        variant === "underlined" ? "underlined" : ""
      }
      ${hasLabelValue ? "" : "label-d-none"}

      `}
      onFocus={onFocus}
      onBlur={onBlur}
      id={id}
    >
      <Input
        placeholder={placeholder}
        style={{ padding: 12, ...styles }}
        disabled={disabled}
        maxLength={max}
        addonAfter={addonAfter}
      />
    </FormItem>
  );
};

export const FormSlider = ({
  label,
  name,
  step,
  marks,
  className,
  formItemStyles,
  styles,
  required,
  disabled,
  formItemClassName,
  ...rest
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      className={`flatFormItem ${
        className ? className : ""
      } ${formItemClassName}
      ${hasLabelValue ? "" : "label-d-none"}

      `}
      style={formItemStyles ?? {}}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      rules={[
        {
          required,
          message: "Required",
        },
      ]}
    >
      <Slider
        disabled={disabled}
        step={step}
        marks={marks}
        style={styles ?? {}}
        {...rest}
      />
    </FormItem>
  );
};

export const FlatFormItem = ({
  initialValue,
  name,
  required,
  type,
  label,
  formItemStyles,
  children,
  className,
}) => {
  return (
    <FormItem
      className={`flatFormItem ${className ? className : ""} `}
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      rules={[
        {
          required,
          message: "Required",
        },

        {
          type: type === "email" ? "email" : null,
          message: "The input is not valid E-mail!",
        },
      ]}
      style={formItemStyles}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: myxss.process(children),
        }}
      ></span>
    </FormItem>
  );
};
